import "./main.scss"

import "blissfuljs"
import _throttle from "lodash/throttle"
import { Gallery } from "./js/gallery"
import { Masonry } from "./js/masonry"
import { OverlayHandler } from "./js/overlayHandler"
import { ToggleElements } from "./js/toggleElements"
import { ScrollMenu } from "./js/scroll-menu"
import { SelfTest } from "./js/selftest"
import { Navigation } from "./js/navigation"
import { initInteractions } from "./js/interactions"
import BigPicture from "bigpicture"
const Parallax = require("parallax-js")

import smoothscroll from "smoothscroll-polyfill"
smoothscroll.polyfill()

window.htmx = require("htmx.org")
const htmx = window.htmx

const $ = window.Bliss
const $$ = $.$

$.ready().then(() => {
  // Init parallax

  const scene = document.querySelectorAll(".parallax-scene")
  if (scene) {
    scene.forEach((e) => new Parallax(e))
  }

  // Init forms

  const appearForms = $$("[data-appear-form]")

  const observeForms = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        entry.target.querySelectorAll("[data-form]").forEach((el) => {
          el.style.animation = `${entry.target.dataset.appear} .8s ${
            Math.random() * 3
          }s forwards ease-out`
        })
      }
    })
  })

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min
  }

  if (appearForms) {
    appearForms.forEach((entry) => {
      const sectionHeight = entry.getBoundingClientRect().height

      let forms = []
      if (sectionHeight < 600) {
        forms = [1]
      } else if (sectionHeight > 600 && sectionHeight < 1000) {
        forms = [1, 1]
      } else {
        forms = [1, 1, 1]
      }

      forms.forEach(() => {
        entry.appendChild(
          $.create({
            tag: "span",
            class: "section--form",
            "data-form": entry.dataset.themeForm,
          }),
        )
      })

      observeForms.observe(entry)
      const form = entry.querySelectorAll("[data-form]")

      const maxSize = 20
      const minSize = 5

      form.forEach((el) => {
        const size = getRandomArbitrary(minSize, maxSize)
        el.style.left = `${Math.random() * 80}%`
        el.style.top = `${Math.random() * 80}%`
        el.style.width = `${size}rem`
        el.style.height = `${size}rem`
      })

      // entry.querySelector("[data-form]").style.left = `${Math.random()*80}%`
      // entry.querySelector("[data-form]").style.top = `${Math.random()*80}%`
      // entry.querySelector("[data-form]").style.width = `${size}rem`
      // entry.querySelector("[data-form]").style.height = `${size}rem`
      // entry.querySelector("[data-form]").style.transform = `rotate(${Math.random()*80}deg) scale(0)`
    })
  }

  // Init interactions

  initInteractions()

  // Toggle
  new Navigation()
  new ToggleElements()

  // Overlay Handler

  const overlay = $("[data-overlay]")

  if (overlay) {
    new OverlayHandler(overlay)
  }

  // selftest

  const selftest = $("[data-selftest]")

  if (selftest) {
    new SelfTest(selftest)
  }

  // Gallery

  const questionGallery = $$(".question-slider:not(.gallery--none)")
  if (questionGallery) {
    questionGallery.forEach((e) => {
      new Gallery(e, {
        interval: 5000,
        autoPlay: false,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
      })
    })
  }

  const newsGallery = $$(".news-slider:not(.gallery--none)")
  if (newsGallery) {
    newsGallery.forEach((e) => {
      new Gallery(e, {
        interval: 5000,
        autoPlay: true,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
      })
    })
  }

  // Masonry
  function doMasonryLayout(parent) {
    parent
      .querySelectorAll("[data-masonry]")
      ?.forEach(
        (gridEl) => new Masonry(gridEl, gridEl.getAttribute("data-masonry")),
      )
  }
  doMasonryLayout(document)

  // cta teasers hover effect
  const counsellingTeaserLeft = $(".counselling-teaser--left")
  const counsellingTeaserRight = $(".counselling-teaser--right")
  const counsellingTeasersWrapper = $(".counselling-teasers--wrapper")
  // let dataCounsellingAnchor = $$("[data-counselling-anchor]")
  //
  // if (dataCounsellingAnchor) {
  //   dataCounsellingAnchor.forEach(el => {
  //     el.addEventListener("click", e => {
  //       console.log(e);
  //     })
  //     // el.addEventListeners('click', e => {
  //     //   console.log(e);
  //     // })
  //   })
  // }
  const mediaQuery = window.matchMedia("(min-width: 750px)")

  if (mediaQuery.matches) {
    if (counsellingTeaserRight) {
      counsellingTeaserRight.addEventListener("mouseover", () => {
        counsellingTeasersWrapper.setAttribute("data-active", "2")
      })

      counsellingTeaserLeft.addEventListener("mouseover", () => {
        counsellingTeasersWrapper.setAttribute("data-active", "1")
      })
    }
  }

  // center list
  function checkCenterList() {
    const centerList = document.querySelector("[data-center-list]")
    const centerListCanton = document.querySelector("[data-center-list-canton]")
    const centerListMap = document.querySelector(".center-list--map")

    if (centerList) {
      const canton = document.querySelector("#id_canton", centerList)
      const cantonName = document.querySelector("option:checked", canton)

      if (cantonName.value) {
        centerListMap.classList.add(
          cantonName.innerHTML
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^\w-]+/g, ""),
        )
        // alert(cantonName.innerHTML.toLowerCase().replace(/\s+/g, '-'))

        if (centerListCanton) {
          centerListCanton.parentElement.classList.remove("hide")
          centerListCanton.innerText = `«${cantonName.innerHTML}»`
        }
      } else if (centerListCanton) {
        centerListCanton.parentElement.classList.add("hide")
      }
    }
  }
  checkCenterList()

  // Scroll menu

  const linkList = $$("[data-link]")
  const targetList = $$("[data-anchor]")

  if (targetList) {
    new ScrollMenu({
      links: linkList,
      offsetMediaQueries: [
        {
          mediaQuery: "(min-width: 1024px)",
          scrollOffset: -150,
          rootMargin: "-100px 0px 0px 0px",
        },
        {
          mediaQuery: "(min-width: 768px)",
          scrollOffset: -50,
          rootMargin: "-50px 0px 0px 0px",
        },
        {
          mediaQuery: "(min-width: 0px)",
          scrollOffset: -50,
          rootMargin: "200px 0px -100px 0px",
        },
      ],
      targets: targetList,
    })
  }

  // video popup

  const videoPopup = $$("[data-video-popup]")

  function openVideo(e) {
    BigPicture({
      el: e.target,
      ytSrc: e.target.dataset.videoSrc,
      autoplay: true,
      dimensions: [1280, 720],
      ytNoCookie: true,
    })
  }
  if (videoPopup) {
    videoPopup.forEach((video) => {
      video.addEventListener("click", (e) => {
        e.preventDefault()
        openVideo(e)
      })
    })
  }

  function regionMapClickable() {
    const form = document.querySelector(".center-list--filter-form")
    const cantones = document.getElementById("svg-map__cantones")
    const select = document.getElementById("id_canton")
    const centerListMap = $(".center-list--map")

    function resetClass() {
      const previousSelection = centerListMap.classList[1] || false
      if (previousSelection) centerListMap.classList.remove(previousSelection)
    }

    if (select) select.addEventListener("change", resetClass)

    if (cantones) {
      cantones.addEventListener("click", (e) => {
        const target = e.target
        const targetRegion = target.getAttribute("data-region")
        const targetClass = target.classList[1]
        resetClass()
        centerListMap.classList.add(targetClass)
        select.value = targetRegion
        htmx.trigger(form, "change")
      })
    }
  }

  htmx.onLoad((el) => {
    checkCenterList()
    regionMapClickable()
    doMasonryLayout(el)
  })

  regionMapClickable()
})
