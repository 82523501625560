const $ = window.Bliss
const $$ = $.$

export class SelfTest {
  constructor(el) {
    this.el = el
    this.questions = $$("[data-selftest-question]", this.el)
    this.selftestButtons = $("[data-selftest-buttons]", this.el)
    this.selftestButton = $$("[data-selftest-button]", this.el)
    this.selftestMessage = $$("[data-selftest-message]", this.el)
    this.selftestMessages = $("[data-selftest-messages]", this.el)
    this.selftestButtonStart = $("[data-selftest-button-start]", this.el)
    this.questionCounter = $("[data-question-counter]", this.el)
    this.selftestPrevious = $("[data-selftest-previous]", this.el)
    this.selftestNext = $("[data-selftest-next]", this.el)
    this.minAnsweredHtml = $("[data-min-answered]", this.el)
    this.thresholdMinHtml = $("[data-threshold-min]", this.el)
    this.selftestTotalQuestions = Number.parseInt(
      this.el.dataset.selftestTotalQuestions,
      10,
    )

    this.minAnswered = 0
    this.thresholdMin = 0
    this.start = false
    this.questionProgress = 1

    this.answers = ["yes"]

    this.addListener()
  }

  addListener() {
    this.selftestButtonStart.addEventListener("click", (e) => {
      e.preventDefault()
      this.start = true
      this.startTest()
      this.selftestButtonStart.classList.add("hide")
    })

    this.selftestPrevious.addEventListener("click", (e) => {
      e.preventDefault()

      if (this.questionProgress > 1) {
        this.questionProgress--
        this.nextQuestion()
      }
    })

    this.selftestNext.addEventListener("click", (e) => {
      e.preventDefault()

      if (this.questionProgress < this.selftestTotalQuestions) {
        this.questionProgress++
        this.nextQuestion()
      }
    })
  }

  startTest() {
    if (this.questionProgress === 1) {
      this.selftestPrevious.classList.add("disabled")
    } else {
      this.selftestPrevious.classList.remove("disabled")
    }

    if (this.start) {
      this.el.classList.add("test--started")

      this.questions.forEach((el, i) => {
        const index = i + 1

        if (index === this.questionProgress) {
          el.classList.remove("hide")
        }

        const selfTestButtons = $$("[data-selftest-button]", el)

        selfTestButtons.forEach((btn) => {
          btn.classList.remove("hide")
          btn.addEventListener("click", (e) => {
            e.preventDefault()

            if (this.selftestTotalQuestions !== this.questionProgress) {
              this.questionProgress++
            }
            // this.answers.push(el.dataset.selftestAnswer)
            // e.target.classList.remove('button--disabled')
            this.nextQuestion()
            el.dataset.answer = btn.dataset.selftestAnswer
            this.answerProgress()
          })
        })
      })
    }
  }

  nextQuestion() {
    this.questionCounter.innerText = this.questionProgress

    if (this.questionProgress === 1) {
      this.selftestPrevious.classList.add("disabled")
    } else {
      this.selftestPrevious.classList.remove("disabled")
    }

    if (this.questionProgress === this.selftestTotalQuestions) {
      this.selftestNext.classList.add("disabled")
    } else {
      this.selftestNext.classList.remove("disabled")
    }

    this.questions.forEach((el, index) => {
      const questionIndex = index + 1
      if (questionIndex === this.questionProgress) {
        el.classList.remove("hide")
      } else {
        el.classList.add("hide")
      }
    })
  }

  previous() {}

  answerProgress() {
    this.minAnswered = $$("[data-answer]").length
    this.thresholdMin = $$("[data-answer=yes]").length
    // this.minAnsweredHtml.innerText = this.minAnswered
    // this.thresholdMinHtml.innerText = this.thresholdMin
    const isHidden = (currentValue) => currentValue.classList.contains("hide")

    this.selftestMessage.forEach((el) => {
      // $yes >= $min && $yes <= $max && $min_answered <= $questions_answered

      // if  (parseInt(el.dataset.messageMinAnswered) <= this.minAnswered && parseInt(el.dataset.messageThresholdMin) >= this.thresholdMin) {
      if (
        this.thresholdMin >=
          Number.parseInt(el.dataset.messageThresholdMin, 10) &&
        this.thresholdMin <=
          Number.parseInt(el.dataset.messageThresholdMax, 10) &&
        Number.parseInt(el.dataset.messageMinAnswered, 10) <= this.minAnswered
      ) {
        el.classList.remove("hide")
        this.selftestMessages.classList.add("active")
        this.el.classList.add("message--open")
      } else {
        el.classList.add("hide")
      }
    })

    if (this.selftestMessage.every(isHidden)) {
      this.selftestMessages.classList.remove("active")
      this.el.classList.remove("message--open")
    }
  }
}
