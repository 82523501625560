const $ = window.Bliss
const $$ = $.$

export class Masonry {
  constructor(container, cellSelector) {
    this.container = container
    this.cellSelector = cellSelector

    this.addEventListeners()
    this.resizeGridItems()
  }

  resizeGridItems = () => {
    const rowStyle = getComputedStyle(this.container)
    const rowGap = Number.parseInt(rowStyle.gridRowGap)
    const rowHeight = Number.parseInt(rowStyle.gridAutoRows) + rowGap

    $$(this.cellSelector, this.container).forEach((el) => {
      const childElements = $$(el.children)
      const elHeight = childElements.reduce((height, child) => {
        return child.getBoundingClientRect().height + height
      }, 0)

      const rowSpan = Math.ceil((elHeight + rowGap) / rowHeight)

      el.style.gridRowEnd = `span ${rowSpan}`
    })
  }

  addEventListeners = () => {
    window.addEventListener("load", this.resizeGridItems)
    window.addEventListener("resize", () => {
      this.resizeGridItems()
    })
  }
}
