import "./TweenMax.min.js"

const $ = window.Bliss
const $$ = $.$

const TweenMax = window.TweenMax
import _throttle from "lodash/throttle"
import "intersection-observer"

export function initInteractions() {
  const mediaQuery = window.matchMedia("(max-width: 750px)")
  const cursor = $(".cursor"),
    follower = $(".cursor-follower")

  if (cursor && !mediaQuery.matches) {
    new cursorInteraction(cursor, follower)
  }

  const hideOnScroll = $$("[ data-hide-on-scroll]")
  if (hideOnScroll) {
    hideOnScroll.forEach((el) => new scrollInteraction(el))
  }

  if (mediaQuery.matches) {
    const appearClippath = document.querySelectorAll(".appear-clippath")
    new teaserIntersectionObserver(appearClippath)
  }
}

class teaserIntersectionObserver {
  constructor(el) {
    this.el = el
    const options = {
      threshold: 0,
      rootMargin: "-180px",
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.style.setProperty("--clip-position", `200px 140px`)
          entry.target.style.setProperty("--clip-size", `120px`)
        } else {
          entry.target.style.setProperty("--clip-position", `200px 140px`)
          entry.target.style.setProperty("--clip-size", `0`)
        }
      })
    }, options)

    this.el.forEach((entry) => {
      observer.observe(entry)
    })
  }
}

class scrollInteraction {
  constructor(el) {
    this.el = el

    this.addListener()
  }

  addListener = () => {
    const scrolledThreshold = 300

    window.addEventListener(
      "scroll",
      _throttle(() => {
        // let offset = window.pageYOffset
        if (window.pageYOffset > scrolledThreshold) {
          $("body").classList.add("scrolled")
          TweenMax.to(this.el, 0.5, { autoAlpha: 0 })
        } else {
          $("body").classList.remove("scrolled")
          TweenMax.to(this.el, 0.5, { autoAlpha: 1 })
        }
      }, 100),
    )
  }
}

class cursorInteraction {
  constructor(cursor, follower) {
    this.cursor = cursor
    this.follower = follower

    this.interactions = $$("[data-interaction]")
    this.clipPathInteraction = $$("[data-interaction-type=clipPath]")
    this.helpCursor = $("[data-help-cursor]")

    // this.info = $(".info")

    this.addListener()
    this.mouseMove(
      this.cursor,
      this.follower,
      this.clipPathInteraction,
      this.info,
      this.mouseX,
      this.mouseY,
    )
  }

  addListener = () => {
    this.interactions.forEach((e) => {
      const interactionColor = e.dataset.interactionColor

      e.addEventListener("mouseenter", () => {
        this.cursor.classList.add(`color--${interactionColor}`)
      })
      // e.addEventListener("mouseleave", () => {
      //   _throttle(() => {
      //   this.cursor.classList.remove(`color--${interactionColor}`)
      //   }, 100)
      // })

      if (e.dataset.interactionType === "clipPath") {
        e.addEventListener("mouseenter", () => {
          this.cursor.classList.add("active-image")
          this.follower.classList.add("active-image")
        })
        e.addEventListener("mouseleave", () => {
          this.cursor.classList.remove("active-image")
          this.follower.classList.remove("active-image")
        })
      } else if (e.dataset.interactionType === "video") {
        e.addEventListener("mouseenter", () => {
          this.cursor.classList.add("active-video")
          this.follower.classList.add("active-video")
        })
        e.addEventListener("mouseleave", () => {
          this.cursor.classList.remove("active-video")
          this.follower.classList.remove("active-video")
        })
      } else if (e.dataset.interactionForm === "triangle") {
        e.addEventListener("mouseenter", () => {
          this.cursor.classList.add("active-triangle")

          this.follower.classList.add("active-triangle")
        })
        e.addEventListener("mouseleave", () => {
          this.cursor.classList.remove("active-triangle")
          this.follower.classList.remove("active-triangle")
        })
      } else if (e.dataset.interactionForm === "rhombus") {
        e.addEventListener("mouseenter", () => {
          this.cursor.classList.add("active-rhombus")
          this.follower.classList.add("active-rhombus")
        })
        e.addEventListener("mouseleave", () => {
          this.cursor.classList.remove("active-rhombus")
          this.follower.classList.remove("active-rhombus")
        })
      } else if (e.dataset.interactionType === "cantons") {
        const newDiv = document.createElement("div")
        this.cursor.classList.add("cantons")
        e.addEventListener("mouseenter", () => {
          this.cursor.appendChild(newDiv)
          this.cursor.classList.add("active-cantons")
          this.follower.classList.add("active-cantons")
        })
        e.addEventListener("mouseover", (event) => {
          newDiv.textContent = event.target.dataset.name
        })
        e.addEventListener("mouseleave", () => {
          newDiv.remove()
          this.cursor.classList.remove("active-cantons")
          this.follower.classList.remove("active-cantons")
        })
      } else {
        e.addEventListener("mouseenter", () => {
          this.cursor.classList.add("active")
          this.follower.classList.add("active")
        })

        e.addEventListener("mouseleave", () => {
          this.cursor.classList.remove("active")
          this.follower.classList.remove("active")
        })
      }
    })
  }

  mouseMove(cursor, follower, clipPath) {
    let posX = 0,
      posY = 0,
      mouseX = 0,
      mouseY = 0

    TweenMax.to({}, 0.016, {
      repeat: -1,
      onRepeat() {
        posX += (mouseX - posX) / 9
        posY += (mouseY - posY) / 9

        TweenMax.set(follower, {
          css: {
            left: posX - 20,
            top: posY - 20,
          },
        })

        TweenMax.set(cursor, {
          css: {
            left: mouseX,
            top: mouseY,
          },
        })
      },
    })

    const setClipObjectStyles = (anyClipPath, e) => {
      anyClipPath.forEach((c) => {
        const rect = c.getBoundingClientRect()
        const x = e ? e.clientX - rect.left : -2000 // initially set clip offcanvas
        const y = e ? e.clientY - rect.top : 0

        const clipObject = $("[data-clipped-image]", c)

        clipObject.style.setProperty("--clip-position", `${x}px ${y}px`)
        c.addEventListener("mouseenter", () => {})
      })
    }

    setClipObjectStyles(clipPath)

    $(document).addEventListener("mousemove", (e) => {
      mouseX = e.pageX
      mouseY = e.pageY

      setClipObjectStyles(clipPath, e)
    })
  }
}
